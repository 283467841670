import { Component } from '@angular/core';
import { Platform, ActionSheetController, NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationEnd, Router } from '@angular/router';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { UtilService } from './services/util.service';
import { ApisService } from './services/apis.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { AuthGuard } from './guard/auth.guard';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],

})
export class AppComponent {

  // newOrders: any[] = [];
  // onGoingOrders: any[] = [];
  // oldOrders: any[] = [];
  // dummy = Array(50);
  venue;
  hideMenu = true;
  thisRoute: any;
  venueName: any = "V-GO";
  activeWeb = false;
  deeplink = "link.vgo.com.ar";
  message: any;
  validation: boolean = false
  all_process_completed: boolean = false
  completed_venue_info: boolean = false
  constructor(

    private afMessaging: AngularFireMessaging,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    // private oneSignal: OneSignal,
    private translate: TranslateService,
    private nativeAudio: NativeAudio,
    private actionSheetController: ActionSheetController,
    private util: UtilService,
    private api: ApisService,
    private navCtrl: NavController,
    private menu: MenuController,
  ) {
    this.menu.enable(false, 'main');

    const lng = localStorage.getItem('language');
    if (!lng || lng === null) {
      localStorage.setItem('language', 'spanish');
    }
    this.translate.use(localStorage.getItem('language'));
    if (localStorage.getItem('uid')) {
      this.deeplink = `https://link.vgo.com.ar/vid/${localStorage.getItem('uid')}`

      this.api.getVenueDetails(localStorage.getItem('uid')).then(data => {
        if (data) {
          console.log("hay data del venue")
          if (data.validation) {
            this.validation = true
            this.hideMenu = false;
            this.all_process_completed = true
          }
          if (data.all_process_completed) {
            this.hideMenu = false;
            this.all_process_completed = true
          }
          this.completed_venue_info = data && data.completed_venue_info ? true : false
          this.venue = data;
          this.venueName = data.name;
          this.menu.enable(true, 'main');
          this.initializeApp();

         
        }else{
          this.api.getPendingVenueDetails(localStorage.getItem('uid')).then(data=>{
            console.log("pending venue", data)
                
                  if(data.validation){
                    this.validation = true
                    this.hideMenu = false;
                    this.all_process_completed = true
                  }
                  if (data.all_process_completed) {
                    this.hideMenu = false;
                    this.all_process_completed = true
                  }
                  this.completed_venue_info = data && data.completed_venue_info ? true : false
                  // this.venue = data;
                  this.venueName = data.name;
                  this.menu.enable(true, 'main');

                 
                this.initializeApp();
                if (this.validation || this.all_process_completed) {
                  this.router.navigate(['orders'])
                } else if (this.completed_venue_info) {
                  this.router.navigate(['merchants-process-final'])
                } else {
                  this.router.navigate(['merchants-process'])
      
                }
                  this.deeplink = `https://link.vgo.com.ar/vid/${data.uid}`
              })
          
        }
        this.listen()
      })
      // .catch(error => {
      //   console.log(error);
      //   this.util.errorToast(this.util.translate('Something went wrong'));
      // });
    }
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: this.util.translate('New Notification'),
      mode: 'md',
      buttons: [{
        text: this.util.translate('OK'),
        icon: 'volume-mute',
        handler: () => {
          console.log('Delete clicked');
          this.nativeAudio.stop('audio').then(() => console.log('done'), () => console.log('error'));
        }
      }, {
        text: this.util.translate('Cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
          this.nativeAudio.stop('audio').then(() => console.log('done'), () => console.log('error'));
        }
      }]
    });

    await actionSheet.present();
  }

  // mandarNoti() {
  //   console.log("LLEGA A LA FUNCION mandarNOti()");

  //   var orderData = {
  //    fcm_token:"c3Tf-FFrDlsXsADI-9cDSq:APA91bHQHvMKUDVpj6EE0L4n09kgUxsaACUTPy09Cy55mp27P0-M9aAlV5YJtIErRa6EeucXu-1otecWEA-0ILmbeI4uT5vAruIFlyGrn_Mhnn5DEl7IPSPtZbgZ2D66Evurxn3vi2cI",
  //    uid: "mfPrtl4nJyZ2r7tsvmYMuBuQ3LP2"
  //   };
  //   console.log("lo que le estoy mandando al preference", orderData);

  //   this.api.sendNotificationVenue({ body: orderData }).subscribe(data => {
  //     this.util.hide();
  //     console.log("ESTA ES LA DATA QUE VUELVE DEL sendnotific", data);

  //   }, (error) => {

  //     console.log(error);
  //     this.util.hide();
  //     this.util.errorToast(error.error.message);
  //   })
  // }
  getPermission(uid) {
    this.afMessaging.requestToken
      .subscribe(
        (token) => {
          localStorage.setItem("tokenNotification", token)
          console.log("el tokennotification", this.venue.tokenNotification);

          let param: { uid: string; tokenNotification: any; };
          if (!this.venue.tokenNotification) {
            param = {
              uid: uid,
              tokenNotification: [token]
            }
            this.venue.tokenNotification = [token]


            this.api.updateVenue(param)
              .then(
                (data) => {
                  this.afMessaging.messages
                    .subscribe((message) => { console.log(message); });
                },
                (error) => {
                  this.util.hide();
                  console.error(error);
                  this.util.errorToast(error);
                }
              )
              .catch((error) => {
                this.util.hide();
                console.log(error);
                this.util.errorToast(error);
              });
          } else if (this.venue.tokenNotification.includes(token)) {
            console.log("Ya teniamos el token");

          } else {
            this.venue.tokenNotification.push(token)
            param = {
              uid: uid,
              tokenNotification: this.venue.tokenNotification
            };

            this.api.updateVenue(param)
              .then(
                (data) => {
                  this.afMessaging.messages
                    .subscribe((message) => { console.log(message); });
                },
                (error) => {
                  this.util.hide();
                  console.error(error);
                  this.util.errorToast(error);
                }
              )
              .catch((error) => {
                this.util.hide();
                console.log(error);
                this.util.errorToast(error);
              });
          }
        },
        (error) => { console.error(error); },
      );
  }

  goToOrder() {
    this.router.navigate(['/orders']);
  }
  listen() {
    // this.afMessaging.messaging
    // .subscribe((message) => { console.log(message); });

    this.afMessaging.messaging.subscribe(
      (messaging: any) => {
        messaging.onMessageCallback = (payload: any) => {
          console.log("llego pedido??", payload)
          var audio = new Audio('/assets/alert.mp3');
          setTimeout(() => {
            audio.play();
          }, 500)
          // this.util.showWarningAlertNotification(
          //  payload.notification.title,
          //   payload.notification.body,
          // ).then(alert => {
          //   // this.util.present().then();
          //   // alert.onDidDismiss().then(async () => {
          //   // // do something
          //   // });
          // });
        };
        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      });
  }

  // showNotification(){
  //   const greeting = new Notification('Llego un Nuevo pedido. V-Go',{
  //     body: 'Nuevo pedido recibido V-Go',
  //   });
  //   return greeting
  // }

  review() {
    this.util.show()
    // console.log("NEWID0", newId)
    const opinion = {
      uid: this.venue.uid,
      name: this.venue.name,
      phone: this.venue.phone,
      message: this.message,
      submitedAt: new Date(),
      venue: true
    }
    console.log(opinion);

    Swal.fire({

      title: 'Sugerencias y/o comentarios <3',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },
      confirmButtonText: 'Enviar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: (result) => {
        if (!result) {
          Swal.showValidationMessage(`Ingresa un comentario y/o sugerencia para continuar.`)
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.makeOpinionId().then(async (newId: any) => {

          console.log("este es el result", result)
          const opinion = {
            uid: this.venue.uid,
            name: this.venue.name,
            phone: this.venue.phone,
            message: result.value,
            submitedAt: new Date(),
            venue: true
          }
          console.log(opinion);
          this.api.addNewOpinion(newId, opinion).then((data) => {
            this.util.hide();
            Swal.fire({
              title: 'Genial',
              text: 'Muchas gracias por compartirlo con nosotrxs :)',
              timer: 1000,
              backdrop: false,
              background: 'white',
              icon: 'success'
            });
          }, error => {
            this.util.hide();
            console.log(error);
            this.util.errorToast(this.util.translate('Something went wrong'));
          }).catch(error => {
            this.util.hide();
            console.log(error);
            this.util.errorToast(this.util.translate('Something went wrong'));
          });
        })
      }
      this.util.hide();

    })
    this.util.hide();

  }



  initializeApp() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.thisRoute = e.url
      }
    });
    this.platform.ready().then(() => {
      console.log("notification access", Notification.permission)

      this.api.checkAuth().then((user: { uid: any }) => {
        if (user) {
          // console.log("ESTE ES EL USER: --->", user, this.venue);
          if (!this.venue.tokenNotification) {
            this.getPermission(user.uid)
          }

         
        }
      })

      this.listen()

      this.nativeAudio.preloadSimple('audio', 'assets/alert.mp3').then((data: any) => {
        console.log('dupletx', data);
      }, error => {
        console.log(error);
      }).catch(error => {
        console.log(error);
      });

      this.platform.backButton.subscribe(async () => {
        console.log('asd', this.router.url, 'ad', this.router.isActive('/tabs/', true));
        if (this.router.url.includes('/tabs/') || this.router.url.includes('/login')) {
          navigator['app'].exitApp();
        }
      });
      this.statusBar.backgroundColorByHexString('#ff384c');
      this.splashScreen.hide();
    });
  }
  copyDeeplink() {
    navigator.clipboard.writeText(this.deeplink).then(
      () => {
        /* clipboard successfully set */
        console.log("sucessfull", this.deeplink)
        this.util.showToast(`Enlace copiado correctamente: ${this.deeplink}`, 'success', 'bottom');
        this.util.hide();
      },
      () => {
        /* clipboard write failed */
        console.log("not succesful", this.deeplink)

      },
    );
  }
  logout() {
    this.api.logout().then(() => {
      this.afMessaging.getToken
        .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
        .subscribe(
          (token) => { console.log('Token deleted!'); },
        );
      this.navCtrl.navigateRoot(['login']);
      location.reload();
    });
  }


  openWhatsapp() {
    let name = "V-Go"
    if (this.venueName) {
      name = this.venueName
    }
    let text =
      "Hola V-Go! Te escribo desde *" +
      name +
      "*"
    var textW = text.split(" ").join("%20");
    window.open(
      "https://api.whatsapp.com/send?phone=+5491138492461&text=" + textW
    );
  }

  // getOrders() {
  //   console.log('vid', localStorage.getItem('uid'));
  //   this.dummy = Array(50);
  //   this.api.getMyOrders(localStorage.getItem('uid')).then((data) => {
  //     this.dummy = [];
  //     console.log('orders', data);
  //     if (data && data.length > 0) {
  //       this.newOrders = [];
  //       this.onGoingOrders = [];
  //       this.oldOrders = [];
  //       data.forEach(element => {
  //         element.order = JSON.parse(element.order);
  //         if (element.status === 'created') {
  //           this.newOrders.push(element);
  //         } else if (element.status === 'accepted' || element.status === 'ongoing' || element.status === 'pending') {
  //           this.onGoingOrders.push(element);
  //         } else if (element.status === 'delivered' || element.status === 'cancel' || element.status === 'rejected') {
  //           this.oldOrders.push(element);
  //         }
  //       });
  //       console.log('new order', this.newOrders);
  //     }
  //   }, err => {
  //     console.log(err);
  //     this.dummy = [];
  //     this.util.errorToast(this.util.translate('Something went wrong'));
  //   }).catch(e => {
  //     console.log(e);
  //     this.dummy = [];
  //     this.util.errorToast(this.util.translate('Something went wrong'));
  //   });
  // }
  goToOldOrders() {
    this.router.navigate(['oldOrders']);
  }
  goToFaq() {
    this.router.navigate(['faq']);
  }
  goToFeedback() {
    this.router.navigate(['feedback']);
  }
}
