// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'http://localhost:5001/v-go-app/us-central1/api/v1',
  apiUrl: 'https://us-central1-v-go-app.cloudfunctions.net/api/v1',
  programSearchDriverUrl: 'https://us-central1-v-go-app.cloudfunctions.net/programSearchDriver',
  firebase: {
    apiKey: "AIzaSyCffqnZYz97NGi7tlIf-zJMt4dz9wr3G2Y",
    authDomain: "v-go-app.firebaseapp.com",
    projectId: "v-go-app",
    storageBucket: "v-go-app.appspot.com",
    messagingSenderId: "40194382803",
    appId: "1:40194382803:web:243f216fb81cf1b9756d26",
    measurementId: "G-BTPWKH83MF",
    databaseURL: "https://v-go-app.firebaseio.com",
    vapidKey:"BLjQFmctUW1eOw87E0uPMXjj-SYgCgrbO8LOpr7imcucm0kyHNVUYUhwEf5QlAgf8WMRwAizzuMYaNWgb8_kgXY"
  },
  onesignal: {
    appId: '2849bd44-0998-4b37-b1d7-1e5cfde4c4a5',
    googleProjectNumber: '40194382803',
    restKey: 'Yzk3NTA1OTItYzM3OS00NTEwLWI5YjItM2UzY2RmNmQ5YmMy'
  },
  stripe: {
    sk: ''
  },
  general: {
    symbol: '$',
    code: 'USD'
  },
  mercadopago: {
    appId: '6189064076350548',
    // redirectUrl: 'http://localhost:8000/venue-profile'
    redirectUrl: 'https://admin.vgo.com.ar/venue-profile'

  },
  merchantProcess: {
    appId: '6189064076350548',
    // redirectUrl: 'http://localhost:8000/profile-payment'
    redirectUrl: 'https://admin.vgo.com.ar/profile-payment'

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
