import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule)
  },
  {
    path: 'oldOrders',
    loadChildren: () => import('./pages/oldOrders/oldOrders.module').then(m => m.OldOrdersPageModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule)
  },
  {
    path: 'venue-profile',
    loadChildren: () => import('./pages/venue-profile/venue-profile.module').then(m => m.VenueProfilePageModule)
  },

  {
    path: 'maps',
    loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsPageModule)
  },
  {
    path: 'foods',
    loadChildren: () => import('./pages/foods/foods.module').then(m => m.FoodsPageModule)
  },
  {
    path: 'add-new-foods',
    loadChildren: () => import('./pages/add-new-foods/add-new-foods.module').then(m => m.AddNewFoodsPageModule)
  },

  {
    path: 'add-new-many',
    loadChildren: () => import('./pages/add-new-many/add-new-many.module').then(m => m.AddNewManyPageModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'add-new-many-foods',
    loadChildren: () => import('./pages/add-new-many-foods/add-new-many-foods.module').then(m => m.AddNewManyFoodsPageModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'add-new-many-variations',
    loadChildren: () => import('./pages/add-new-many-variations/add-new-many-variations.module').then(m => m.AddNewManyVariationsPageModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule)
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./pages/order-detail/order-detail.module').then(m => m.OrderDetailPageModule)
  },
  {
    path: 'add-category',
    loadChildren: () => import('./pages/add-category/add-category.module').then(m => m.AddCategoryPageModule)
  },
  {
    path: 'tracker',
    loadChildren: () => import('./pages/tracker/tracker.module').then(m => m.TrackerPageModule)
  },
  {
    path: 'banking',
    loadChildren: () => import('./pages/banking/banking.module').then(m => m.BankingPageModule)
  }, {
    path: 'inbox',
    loadChildren: () => import('./pages/inbox/inbox.module').then(m => m.InboxPageModule)
  }, {
    path: 'select-drivers',
    loadChildren: () => import('./pages/select-drivers/select-drivers.module').then(m => m.SelectDriversPageModule)
  },
  {
    path: 'new-order',
    loadChildren: () => import('./pages/new-order/new-order.module').then( m => m.NewOrderPageModule)
  },
  {
    path: 'preview',
    loadChildren: () => import('./pages/preview/preview.module').then( m => m.PreviewPageModule)
  },
  {
    path: 'schedule',
    loadChildren: () => import('./pages/schedule/schedule.module').then( m => m.SchedulePageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'merchants',
    loadChildren: () => import('./pages/merchants/merchants.module').then( m => m.MerchantsPageModule)
  },
  {
    path: 'merchants-process',
    loadChildren: () => import('./pages/merchants-process/merchants-process.module').then( m => m.MerchantsProcessPageModule)
  },
  {
    path: 'merchants-process-final',
    loadChildren: () => import('./pages/merchants-process-final/merchants-process-final.module').then( m => m.MerchantsProcessFinalPageModule)
  },
  {
    path: 'profile-images',
    loadChildren: () => import('./profile-images/profile-images.module').then( m => m.ProfileImagesPageModule)
  },
  {
    path: 'profile-menu',
    loadChildren: () => import('./profile-menu/profile-menu.module').then( m => m.ProfileMenuPageModule)
  },
  {
    path: 'profile-payment',
    loadChildren: () => import('./profile-payment/profile-payment.module').then( m => m.ProfilePaymentPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then( m => m.FeedbackPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
