import { UtilService } from 'src/app/services/util.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.page.html',
  styleUrls: ['./schedule.page.scss'],
})
export class SchedulePage implements OnInit {
  productName: any = '';
  desc: any = '';
  lists: any;
  schedule: any;
  days = {
    0: 'Lunes',
    1: 'Martes',
    2: 'Miércoles',
    3: 'Jueves',
    4: 'Viernes',
    5: 'Sábado',
    6: 'Domingo',

  }
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private util: UtilService
  ) {
    this.schedule = this.navParams.get('schedule');

    [0,1,2,3,4,5,6].forEach((day) => {
      this.schedule[day] = this.schedule[day].map((range) => {
        if(!range.close || !range.open) return {open: '', close: ''}

        const hoursOpen = Number(range.open.split(':')[0])
        const minutesOpen = Number(range.open.split(':')[1])

        const hoursClose = Number(range.close.split(':')[0])
        const minutesClose = Number(range.close.split(':')[1])

        const open = new Date(new Date().setHours(hoursOpen, minutesOpen)).toString();
        const close = new Date(new Date().setHours(hoursClose, minutesClose)).toString();

        return {
          open, 
          close
        }
      })
    })
  }

  ngOnInit() {
  }

  closeIt() {
    console.log('it');
    this.modalController.dismiss();
  }

  saveSchedule() {
    [0,1,2,3,4,5,6].forEach((day) => {
      this.schedule[day] = this.schedule[day].map((range) => {
        if(!range.close || !range.open) return {open: '', close: ''}
        
        return {
          open: ('0' + new Date(range.open).getHours()).slice(-2) + ':' + ('0' + new Date(range.open).getMinutes()).slice(-2), 
          close: ('0' + new Date(range.close).getHours()).slice(-2) + ':' + ('0' + new Date(range.close).getMinutes()).slice(-2), 
        }
      })
    })
    this.modalController.dismiss(this.schedule);
  }

  cleanSchedule(schedule) {
    this.schedule[schedule.day][schedule.range] = {open: '', close: ''}
  }

}
