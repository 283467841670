import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { BehaviorSubject } from "rxjs";
import * as firebase from "firebase";
// import * as admin from "firebase-admin"
import { AngularFirestore } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { isNgTemplate } from "@angular/compiler";
import { AngularDelegate } from "@ionic/angular";
import { AngularFireMessaging } from '@angular/fire/messaging';


export class AuthInfo {
  constructor(public $uid: string) { }

  isLoggedIn() {
    return !!this.$uid;
  }
}

@Injectable({
  providedIn: "root",
})
export class ApisService {
  static UNKNOWN_USER = new AuthInfo(null);
  db = firebase.firestore();
  api = environment.apiUrl;
  programSearchDriverUrl = environment.programSearchDriverUrl;

  public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(
    ApisService.UNKNOWN_USER
  );
  constructor(
    private afMessaging: AngularFireMessaging,
    private fireAuth: AngularFireAuth,
    private adb: AngularFirestore,
    private http: HttpClient
  ) { }

  public checkAuth() {
    return new Promise((resolve) => {
      this.fireAuth.auth.onAuthStateChanged((user) => {
        resolve(user);
      });
    });
  }

  public login(email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
          if (res.user) {
            this.db
              .collection("users")
              .doc(res.user.uid)
              .update({
                fcm_token: localStorage.getItem("fcm")
                  ? localStorage.getItem("fcm")
                  : "",
              });
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
          }
        })
        .catch((err) => {
          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }
  public getVenueTypes(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venueTypes').get().subscribe((types: any) => {
        var data = types.docs.map(element => {
          var item = element.data();
          item.id = element.id;
          return item;
        })
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getCities(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('cities').get().subscribe((venue: any) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  public getVenues(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue', ref => ref.orderBy('createdAt', 'desc').limit(10)) // Use the ref to limit the query
        .get()
        .subscribe((venue: any) => {
          let data = venue.docs.map(element => {
            let item = element.data();
            item.id = element.id;
            return item;
          });
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }
  

  public register(
    emails: string,
    pwd: string,
    fnames: string,
    lnames
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth
        .createUserWithEmailAndPassword(emails, pwd)
        .then((res) => {
          if (res.user) {
            this.db.collection("users").doc(res.user.uid).set({
              uid: res.user.uid,
              email: emails,
              fname: fnames,
              lname: lnames,
              type: "venue",
              status: "active",
              createdAt: new Date()
            });
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
          }
        })
        .catch((err) => {
          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }



  public logout(): Promise<void> {
    localStorage.removeItem("uid");
    this.authInfo$.next(ApisService.UNKNOWN_USER);
    // this.db.collection('users').doc(localStorage.getItem('uid')).update({ 'fcm_token': firebase.firestore.FieldValue.delete() })
    return this.fireAuth.auth.signOut();
  }

  public resetPassword(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth
        .sendPasswordResetEmail(email)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(`reset failed ${err}`);
        });
    });
  }

  public checkEmail(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth
        .fetchSignInMethodsForEmail(email)
        .then((info: any) => {
          resolve(info);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProfile(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(id)
        .get()
        .subscribe(
          (profile: any) => {
            resolve(profile.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getMessages(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("messages")
        .doc(id)
        .collection("chats")
        .get()
        .subscribe(
          (messages: any) => {
            // console.log(messages);
            let data = messages.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public updateProfile(uid, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("users")
        .doc(uid)
        .update(param)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getPendingVenueDetails(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("pendingVenues")
        .doc(id)
        .get()
        .subscribe(
          (venue: any) => {
            resolve(venue.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getVenueDetails(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("venue")
        .doc(id)
        .get()
        .subscribe(
          (venue: any) => {
            resolve(venue.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getVenueCategories(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("categories", (ref) =>
          ref.where("uid", "==", id).orderBy("order")
        )
        .get()
        .subscribe(
          (venue) => {
            var data = venue.docs.map((element) => {
              var item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public updateVenueCategoies(id, cid, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("categories")
        .doc(cid)
        .update(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public updateVenueCategoriesMany(cid, params): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const batch = this.db.batch();
        const categories = await this.adb
          .collection("categories", (ref) => ref.where("uid", "==", cid))
          .get()
          .toPromise();
        categories.docs.forEach((doc) => {
          var item = doc.data();
          const docRef = this.db.collection("categories").doc(item.id);
          batch.update(docRef, { order: params[item.id] });
        });
        batch.commit();
        resolve(true);
      } catch (err) {
        // console.log(err);
        reject(err);
      }
    });
  }

  public getFoodWithId(uid: any, id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("foods")
        .doc(uid)
        .collection("all")
        .doc(id)
        .get()
        .then(
          (data) => {
            // console.log("**", data.data());
            var item = data.data();
            item.id = data.id;
            // console.log("LA CATEGORIA DEL FOOD", item.cid.id);
            data
              .data()
              .cid.get()
              .then(function (doc) {

                item.cid = doc.data();
                item.cid.id = doc.id;
                item.cid.name = doc.name
              });
            resolve(item);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // .where('deleted', '!=', true)
  public async getFoods(uid: any): Promise<any> {
    try {
      const data = await this.db
        .collection("foods")
        .doc(uid)
        .collection("all")
        .where("deleted", "==", false)
        .get();

      const usersPromises = data.docs.map(async (doc) => {
        const item = doc.data();
        const cidDoc = await item.cid.get();
        item.cid = cidDoc.data();
        item.cid.id = cidDoc.id;
        item.id = doc.id;
        return item;
      });

      const users = await Promise.all(usersPromises);

      return users;
    } catch (error) {
      throw error;
    }
  }


  public getCateFoods(cid: any): Promise<any> {
    // console.log("Trayendo los productos de la categoria con el ID:", cid);

    return new Promise<any>((resolve, reject) => {
      // Supongamos que "db" es una referencia a tu instancia de Firestore

      // Referencia a la categoría específica en "categories/XXX"
      const categoryRef = this.db.collection("categories").doc(cid);

      // Realizar la consulta en la colección "all"
      this.db.collectionGroup("all")
        .where("cid", "==", categoryRef)
        .get()
        .then(
          (data) => {
            var users = data.docs.map((doc) => {
              var item = doc.data();
              item.id = doc.id;
              return item;
            });
            resolve(users);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          console.error("Error al realizar la consulta:", error);
        });
    });
  }

  public addFood(uid, id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("foods")
        .doc(uid)
        .collection("all")
        .doc(id)
        .set(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public updateFood(uid, id, param): Promise<any> {
    // console.log("Updating food ID:", id);

    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("foods")
        .doc(uid)
        .collection("all")
        .doc(id)
        .update(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public deletePendingVenue(uid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("pendingVenues")
        .doc(uid)
        .delete()
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public removeFood(uid, id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("foods")
        .doc(uid)
        .collection("all")
        .doc(id)
        .delete()
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public removeMp(vid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const venue = this.adb.collection("venue").doc(vid);

      venue.update({
        payment: null,
      })
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public updateFoodVariations(uid, id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const foodRef = this.db
        .collection("foods")
        .doc(uid)
        .collection("all")
        .doc(id);
      this.db
        .runTransaction((transaction) => {
          return transaction.get(foodRef).then((doc) => {
            if (!doc.data().variations) {
              transaction.set(foodRef, param);
            } else {
              transaction.update(foodRef, param);
            }
          });
        })
        .then(function (data) {
          resolve(data);
          // console.log("Transaction successfully committed!");
        })
        .catch(function (error) {
          reject(error);
          // console.log("Transaction failed: ", error);
        });
    });
  }

  public addVenueCategoies(id, cid, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("categories")
        .doc(cid)
        .set(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public createPendingVenue(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("pendingVenues")
        .doc(informations.uid)
        .set(informations)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }
  public createVenue(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("venue")
        .doc(informations.uid)
        .set(informations)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updatePendingVenue(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("pendingVenues")
        .doc(informations.uid)
        .update(informations)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }
  public updateVenue(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("venue")
        .doc(informations.uid)
        .update(informations)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendNotification(msg, title, id) {
    const body = {
      app_id: environment.onesignal.appId,
      include_player_ids: [id],
      headings: { en: title },
      contents: { en: msg },
      data: { task: msg },
    };
    const header = {
      headers: new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", `Basic ${environment.onesignal.restKey}`),
    };
    return this.http.post(
      "https://onesignal.com/api/v1/notifications",
      body,
      header
    );
  }

  sendNotificationFcm(tokenNotification) {

    var audio = new Audio('/assets/alert.mp3');
    setTimeout(() => {
      audio.play();

    }, 500)
    // var payload = {
    //   notification: {
    //     title: "This is a Notification",
    //     body: "This is the body of the notification message."
    //   }
    // };

    // var options = {
    //   priority: "high",
    //   timeToLive: 60 * 60 * 24
    // };
    //   this.afMessaging.messaging().sendToDevice(tokenNotification , payload, options)
    // .then(function(response) {
    //   // console.log("Successfully sent message:", response);
    // })
    // .catch(function(error) {
    //   // console.log("Error sending message:", error);
    // });

  }

  public getMyOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection("orders", (ref) => ref.where("restId", "==", id).orderBy('createdAt', 'desc').limit(50))
        .get()
        .subscribe(
          (venue) => {

            let data = venue.docs.map((element) => {
              let item = element.data();
              item.uid.get().then(function (doc) {
                item.uid = doc.data();
                //item.uid.id = doc.id;

              });
              item.id = element.id;
              // if (item.status == "created") {
              //   var title = "Nuevo Pedido V-Go";
              //   let icon = 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png';
              //   var body = "Aceptalo en la app! <3";
              //   let showNotification = document.visibilityState !== "visible";
              //   // if(showNotification) { 
              //   var notification = new Notification(title, { body, icon });

              //   // }
              //   this.sendNotificationFcm(localStorage.getItem("tokenNotification"))
              // }
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  

  public getTodayOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        let now = new Date();
        let yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        let firebaseYesterday = firebase.firestore.Timestamp.fromDate(yesterday);
        const status = [
            "created",
            "accepted",
            "pending",
            "assigned",
            "picking_up",
            "on_route",
            "arrived",
        ];

        // Query 1: Orders from the last 24 hours
        let query1 = this.adb.collection("orders", ref =>
            ref.where("restId", "==", id)
               .where('createdAt', '>', firebaseYesterday)
               .orderBy('createdAt', 'desc')
        ).get().toPromise();

        // Query 2: Orders whose status is not "delivered"
        let query2 = this.adb.collection("orders", ref =>
            ref.where("restId", "==", id)
               .where('status', 'in', status)
        ).get().toPromise();

        // Combine results
        Promise.all([query1, query2])
            .then(([snapshot1, snapshot2]) => {
                console.log("Snapshot 1 size:", snapshot1.size); // Debug log
                console.log("Snapshot 2 size:", snapshot2.size); // Debug log

                let combinedData = [...snapshot1.docs, ...snapshot2.docs];
                
                // Remove duplicates based on order ID
                let uniqueOrders = Array.from(new Set(combinedData.map((doc: any) => doc.id)))
                    .map(id => combinedData.find((doc: any) => doc.id === id));
                
                // Map data
                let data = uniqueOrders.map((element: any) => {
                    let item = element.data();
                    item.id = element.id;
                    return item;
                });

                resolve(data);
            })
            .catch(error => {
                console.error("Error in queries:", error); // Debug log
                reject(error);
            });
    });
}



  public getAllOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection("orders", (ref) => ref.where("restId", "==", id).orderBy('createdAt', 'desc'))
        .get()
        .subscribe(
          (venue) => {

            let data = venue.docs.map((element) => {
              let item = element.data();
              item.uid.get().then(function (doc) {
                item.uid = doc.data();
                //item.uid.id = doc.id;

              });
              item.id = element.id;
              // if (item.status == "created") {
              //   var title = "Nuevo Pedido V-Go";
              //   let icon = 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png';
              //   var body = "Aceptalo en la app! <3";
              //   let showNotification = document.visibilityState !== "visible";
              //   // if(showNotification) { 
              //   var notification = new Notification(title, { body, icon });

              //   // }
              //   this.sendNotificationFcm(localStorage.getItem("tokenNotification"))
              // }
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getOrderById(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("orders")
        .doc(id)
        .get()
        .subscribe(
          async (order: any) => {
            let data = await order.data();
            await data.uid.get().then(function (doc) {
              data.uid = doc.data();
              data.uid.id = doc.id;
            });
            await data.vid.get().then(function (doc) {
              data.vid = doc.data();
              data.vid.id = doc.id;
            });
            if (data && data.dId && !data.dId.cabify) {
              await data.dId.get().then(function (doc) {
                data.dId = doc.data();
                data.dId.id = doc.id;
              });
            }
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public updateOrderStatus(id, value, data?): Promise<any> {
    const param = {
      status: value,
      ...data,
    };
    if (value === "accepted") {
      param["acceptedAt"] = new Date();
    }
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("orders")
        .doc(id)
        .update(param)
        .then(async (order: any) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateOrder(id, param): Promise<any> {
    if (param.dId && !param.dId.cabify) {
      param.dId = this.db.collection("users").doc(param.dId);
    }
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("orders")
        .doc(id)
        .update(param)
        .then(async (order: any) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getDrivers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users", (ref) => ref.where("type", "==", "delivery"))
        .get()
        .subscribe(
          async (venue) => {
            let data = venue.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getMyReviews(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("reviews", (ref) => ref.where("restId", "==", id))
        .get()
        .subscribe(
          async (review) => {
            let data = review.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              item.uid.get().then(function (doc) {
                item.uid = doc.data();
              });
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  createCredentialsMP(body) {
    return this.http
      .post<any>(`${this.api}/payments/create_credentials`, body)
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        })
      );
  }

  httpPost(url, body) {
    const header = {
      headers: new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded")
        .set("Authorization", `Bearer ${environment.stripe.sk}`),
    };
    const order = this.JSON_to_URLEncoded(body);
    // console.log(order);
    return this.http.post(url, order, header);
  }

  JSON_to_URLEncoded(element, key?, list?) {
    let new_list = list || [];
    if (typeof element == "object") {
      for (let idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + "[" + idx + "]" : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + "=" + encodeURIComponent(element));
    }
    return new_list.join("&");
  }

  public getSubCategories(type): Promise<any> {
    // console.log(type);
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("subcategories", (ref) => ref.where("type", "==", type))
        .get()
        .subscribe(
          (category: any) => {
            // resolve(category.data());
            let data = category.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getFeedback(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("feedback", (ref) => ref.where("vid", "==", id))
        .get()
        .subscribe(
          async (review) => {
            let data = review.docs.map((element) => {
              let item: any = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getAllSubCategories(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('subcategories').get().subscribe((subcategories: any) => {
        var data = subcategories.docs.map(element => {
          var item = element.data();
          item.id = element.id;
          return item;
        })
        // console.log("getSubCategories", data);

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public addNewOpinion(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("opinions")
        .doc(id)
        .set(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }


  public getLastOpinionId(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("lastId")
        .doc("opinions")
        .get()
        .then(
          async (lastId) => {
            let data = lastId.data().id;
            // console.log("lastId", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public makeOpinionId(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getLastOpinionId().then(
        async (lastId) => {
          // console.log("lastIdId", lastId);
          let newIdnum = lastId + 1;
          // console.log("newIdnum!", newIdnum);
          this.db
            .collection("lastId")
            .doc("opinions")
            .set({
              id: newIdnum,
            })
            .then(() => {
              // console.log("Document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
          let newId = JSON.stringify(newIdnum);
          resolve(newId);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async programSearchDrivers(orderId): Promise<any> {
    // console.log("NUEVO este es el orderId de programSearchDrivers()", orderId.toString());
    return new Promise<any>((resolve, reject) => {
      fetch('https://us-central1-v-go-app.cloudfunctions.net/programSearchDriver/v1', {
        method: 'POST',
        body: orderId.toString()
      }).then(data => {
        resolve(data);
      }).catch((error) => {
        reject(error)
        // console.log("ERROR AL PROGRAMAR SEARCHDRIVERS", error);
      });
    })
  }

  async peyaConfirm(order): Promise<any> {
    // console.log("NUEVO este es el orderId de programSearchDrivers()", order.orderId.toString());
    return new Promise<any>((resolve, reject) => {
      fetch(`https://courier-api.pedidosya.com/v3/shippings/estimates/${order.estimateId}/confirm`, {
        method: 'POST',
        body: JSON.stringify({
          "deliveryOfferId": order.deliveryOfferId,
          "isTest": true
        })
      }).then(data => {
        resolve(data);
      }).catch((error) => {
        reject(error)
        // console.log("ERROR AL PROGRAMAR SEARCHDRIVERS", error);
      });
    })
  }


  // public sendNotificationVenue({ body }) {
  //   // console.log("LLEGA A LA API send notification", body);
  //   return this.http
  //     .post("http://localhost:5001/v-go-app/us-central1/api/v1/venues/sendNotificationVenue", body)
  //     .pipe(
  //       map((data: any) => {
  //         // console.log("respuesta de la fn api sendnotification venue",data);
  //         return data;
  //       })
  //     );
  // }

  notDeleteAllFoods() {
    // console.log("LLEGA A LA FUNCION");


    return new Promise<any>((resolve, reject) => {
      let products = []
      let notProducts = []
      let ref = this.db.collectionGroup('all');
      ref.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let plato = doc.data()
          if (plato.price) {
            if (!plato.deleted) {
              products.push(plato);
            } else {
              notProducts.push(plato);
            }
            if (plato.id == 'Wul850gFfh') {
              // console.log("ESTE ES V-GO", plato);
            }
          }
        });
        // console.log(products);
        // console.log(notProducts);
        products.forEach(product => {
          this.adb
            .collection("foods")
            .doc(product.uid)
            .collection("all")
            .doc(product.id)
            .update({ deleted: false })
            .then(() => {
              // console.log("UPDATED");

            })
        })
        // console.log("FINISH");


      });

    });
  }

  async getDocumentsWithVariations() {
    const snapshot = await this.db.collectionGroup('all')
      .where('price', '>', 0) // Filtrar por documentos con variations.items con length > 0
      .get();

    const documents = [];
    snapshot.forEach(doc => {
      let element = doc.data()
      element.id = doc.id
      documents.push(element);
    });

    // console.log(`Trajo ${documents.length} documentos`);

    return documents;
  }

  async updateItemsStatus(document) {
    let variations = document.variations ? document.variations : [];
    if (variations.length > 0) {
      variations.forEach(sub => {
        sub.items.forEach(variant => {
          variant.status = true
        });
      });

      await this.adb.collection('foods').doc(document.uid).collection('all').doc(document.id).update({
        variations: variations,
      });
      // console.log(`Updated items status in document ${document.id}`, document);
    }
  }

  async updateDocuments() {
    // console.log("llega a updateDocuments");

    const documents = await this.getDocumentsWithVariations();
    const updatePromises = documents.map(document => {
      this.updateItemsStatus(document)
    });

    await Promise.all(updatePromises);
    // console.log('All documents updated');
  }

}
