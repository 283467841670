import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApisService } from 'src/app/services/apis.service';
import { UtilService } from 'src/app/services/util.service';
import { Router, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';


@Component({
  selector: 'app-card-order',
  templateUrl: './card-order.component.html',
  styleUrls: ['./card-order.component.scss'],
})
export class CardOrderComponent implements OnInit {
  takeaway: boolean = false;
  infoOrder: any;
  constructor(
    private api: ApisService,
    private util: UtilService,
    private router: Router,
    ) { }

  @Input() item: object;
  @Input() venueEstimatedPreparationTime: any;
  @Output() estimatedPreparationTime = new EventEmitter<any>();

  ngOnInit() {
    this.infoOrder = this.item
    this.takeaway = this.infoOrder.takeaway

    console.log("este es el item", this.item)
    console.log("Es takeaway", this.takeaway)
  }

  
  goToOrderDetail(ids) {

    const navData: NavigationExtras = {
      queryParams: {
        id: ids
      }
    };
    this.router.navigate(['/order-detail'], navData);
  }

  
  markOrderAsAccepted(event, order) {
    // if(order.christmas && order.christmas_total){
    //   console.log("preparar todo para el 24")
    //   Swal.fire({
    //     title: this.util.translate("success"),
    //     text: "Preparar pedido para el 24/12",
    //     icon: "success",
    //     timer: 2000,
    //     backdrop: false,
    //     background: "white",
    //   });
    //   this.changeStatus({ order, status: 'accepted' })
    // }else if(order.christmas && !order.christmas_total){
    //   console.log("preparar para hoy y para el 24")
    //   Swal.fire({
    //     title: 'Tiempo de preparación',
    //     text: '¿En cuántos minutos retiramos los productos que se entregan hoy de la orden #' + order.orderId + '?',
    //     showCancelButton: true,
    //     cancelButtonText: 'Cancelar',
    //     showConfirmButton: true,
    //     confirmButtonText: 'Aceptar',
    //     backdrop: false,
    //     background: 'white',
    //     inputPlaceholder: String(this.venueEstimatedPreparationTime),
    //     input: 'number'
    //   }).then((data) => {
    //     console.log("ESTA ES LA DATA", data);
    //     if (data && data.isConfirmed) {
    //       if (data.value) {
    //         this.estimatedPreparationTime.emit(Number(data.value))
    //         this.venueEstimatedPreparationTime = Number(data.value)
    //       }
    //       event.stopPropagation();
    //       event.preventDefault();
  
    //       this.changeStatus({ order, status: 'accepted' })
    //       this.util.hide()
    //     }
    //   });
    // }else{
      Swal.fire({
        title: 'Tiempo de preparación',
        text: '¿En cuántos minutos retiramos la orden #' + order.orderId + '?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        backdrop: false,
        background: 'white',
        inputPlaceholder: String(this.venueEstimatedPreparationTime),
        input: 'number'
      }).then((data) => {
        console.log("ESTA ES LA DATA", data);
        if (data && data.isConfirmed) {
          if (data.value) {
            this.estimatedPreparationTime.emit(Number(data.value))
            this.venueEstimatedPreparationTime = Number(data.value)
            this.util.hide()
          }
          event.stopPropagation();
          event.preventDefault();
          this.util.hide()
          this.changeStatus({ order, status: 'accepted' })
        }
      });
    }
  // }

  async changeStatus({ status, order }) {
    /*if (status === 'accepted') {
      console.log('accepted', this.drivers);
      this.presentModal();
    } else {*/

    // this.util.show(this.util.translate('Please wait'));
    const estimatedPreparationTime = this.venueEstimatedPreparationTime !== undefined ? moment().add(this.venueEstimatedPreparationTime, 'minutes') : moment().add(0, 'minutes')
    console.log("estimatedpereparationtime", estimatedPreparationTime);

    this.api.updateOrderStatus(order.id, status, { estimatedPreparationTime: estimatedPreparationTime.toDate() }).then(async (data) => {
      this.util.hide()

    if(!order.christmas_total){
      this.api.programSearchDrivers(order.id).then((response) => {
        console.log("la respuesta de programar el envio", response)
        this.util.hide();

      }, error => {
        this.util.hide();
        console.log(error);
        this.util.errorToast(error);
      }).catch(error =>{
        console.error(error);
        this.util.errorToast(error)
        this.util.hide();
      })
    }
      this.util.publishNewAddress('hello');
      this.util.hide();

      Swal.fire({
        title: this.util.translate('success'),
        text: this.util.translate('Order status changed to ') + this.util.translate(status),
        icon: 'success',
        timer: 2000,
        backdrop: false,
        background: 'white'
      });
      
    }).catch(error => {
      console.log(error);
      this.util.hide();
      this.util.errorToast(this.util.translate('Something went wrong'));
    });
    //}

  }


  markOrderAsDelivered(event, order) {
    Swal.fire({
      title: '¿Entregaste el pedido?',
      text: '',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Entregado',
      backdrop: false,
      background: 'white'
    }).then((data) => {
      console.log(data);
      if (data && data.value) {
        event.stopPropagation();
        event.preventDefault();
        this.util.hide()
        let param;
        if(this.takeaway){
          param = {
            deliveredByVenue: new Date(),
            status : 'delivered'
          }
        }else{
          param = {
            deliveredByVenue: new Date()
          }
        }
        
        this.updateOrder({ id: order.id, param })
      }
    });
  }

  updateOrder({ id, param }: { id: string; param: any; }) {
    this.util.show(this.util.translate('Please wait'));
    this.api.updateOrder(id, param).then((data) => {
      console.log('data', data);
      this.util.publishNewAddress('hello');
      this.util.hide();
    }).catch(error => {
      console.log(error);
      this.util.hide();
      this.util.errorToast(this.util.translate('Something went wrong'));
    });
  }


  getCurrency() {
    return this.util.getCurrecySymbol();
  }
}
