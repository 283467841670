import { Injectable } from '@angular/core';
import { LoadingController, AlertController, ToastController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  loader: any;
  isLoading = false;

  details: any;
  private events = new Subject<any>();
  private profile = new Subject<any>();
  constructor(
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    private toastCtrl: ToastController,
    public router: Router,
    private navCtrl: NavController,
    private translateService: TranslateService
  ) {
  }
  /*
  Start Loader
  Call this method to Start your Loader
  */

  async show(title?) {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: title,
      spinner: 'bubbles',
    }).then(a => {
      a.present().then(() => {
        // console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }
  scheduleIsOpen(schedule) {

    // console.log("este es el schedule ==>" , schedule);
    
    let diaHoy = new Date().getDay();
    let horaHoy = new Date().getHours();
    let minutosHoy = new Date().getMinutes();

    if (diaHoy == 0) {
      diaHoy = 6;
    } else if (diaHoy == 1) {
      diaHoy = 0;
    } else if (diaHoy == 2) {
      diaHoy = 1;
    } else if (diaHoy == 3) {
      diaHoy = 2;
    } else if (diaHoy == 4) {
      diaHoy = 3;
    } else if (diaHoy == 5) {
      diaHoy = 4;
    } else if (diaHoy == 6) {
      diaHoy = 5;
    }

    // console.log(
    //   "diaHoy",
    //   diaHoy,
    //   "horaHoy",
    //   horaHoy,
    //   "minutosHoy",
    //   minutosHoy
    // );

    const scheduleToday = schedule[diaHoy];

    // console.log("SCHEDULE TODAY", scheduleToday);

    let horario0Hoy = {
      hor0open: 0,
      hor0close: 0,
      min0open: 0,
      min0close: 0,
    };

    let horario1Hoy = {
      hor1open: 0,
      hor1close: 0,
      min1open: 0,
      min1close: 0,
    };

    if (scheduleToday[0].open != "") {
      // console.log("scheduleToday[0]", scheduleToday[0]);

      horario0Hoy = {
        hor0open: parseInt(scheduleToday[0].open.split(":")[0]),
        hor0close: parseInt(scheduleToday[0].close.split(":")[0]),
        min0open: parseInt(scheduleToday[0].open.split(":")[1]),
        min0close: parseInt(scheduleToday[0].close.split(":")[1]),
      };
    }

    if (scheduleToday[1] != "") {
      // console.log("scheduleToday[1]", scheduleToday[1]);

      horario1Hoy = {
        hor1open: parseInt(scheduleToday[1].open.split(":")[0]),
        hor1close: parseInt(scheduleToday[1].close.split(":")[0]),
        min1open: parseInt(scheduleToday[1].open.split(":")[1]),
        min1close: parseInt(scheduleToday[1].close.split(":")[1]),
      };
    }

    // console.log("SCHEDULE TODAY", horario0Hoy, horario1Hoy);
    if (
      (horaHoy >= horario0Hoy.hor0open  && horaHoy <= horario0Hoy.hor0close) ||
         (horaHoy >= horario1Hoy.hor1open && horaHoy <= horario1Hoy.hor1close )
    ) {
      if (
        (horario0Hoy.hor0open == horaHoy ||
          horario1Hoy.hor1open == horaHoy) &&
        (horario0Hoy.min0open <= minutosHoy ||
          horario1Hoy.min1open <= minutosHoy)
      ) {
        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!ESTA ABIERTO!!!!!!!!!!!!!!!!!!!!!!"
        // );
        return true;
      } else if (
        (horario0Hoy.hor0close == horaHoy ||
          horario1Hoy.hor1close == horaHoy) &&
        (horario0Hoy.min0close >= minutosHoy ||
          horario1Hoy.min1close >= minutosHoy)
      ) {
        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!ESTA ABIERTO!!!!!!!!!!!!!!!!!!!!!!"
        // );
        return true;
      } else if (
        horario0Hoy.hor0open == horaHoy ||
        horario1Hoy.hor1open == horaHoy ||
        horario0Hoy.hor0close == horaHoy ||
        horario1Hoy.hor1close == horaHoy
      ) {
        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!ESTA CERRADO!!!!!!!!!!!!!!!!!!!!!!"
        // );
        return false;
      } else {
        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!ESTA ABIERTO!!!!!!!!!!!!!!!!!!!!!!"
        // );
        return true;
      }
    } else {
      // console.log(
      //   "!!!!!!!!!!!!!!!!!!!!ESTA CERRADO!!!!!!!!!!!!!!!!!!!!!!"
      // );
      return false;
    }
  }
  
  scheduleDelDia(schedule) {

    console.log("este es el schedule ==>" , schedule);
    
    let diaHoy = new Date().getDay();
    let horaHoy = new Date().getHours();
    let minutosHoy = new Date().getMinutes();

    if (diaHoy == 0) {
      diaHoy = 6;
    } else if (diaHoy == 1) {
      diaHoy = 0;
    } else if (diaHoy == 2) {
      diaHoy = 1;
    } else if (diaHoy == 3) {
      diaHoy = 2;
    } else if (diaHoy == 4) {
      diaHoy = 3;
    } else if (diaHoy == 5) {
      diaHoy = 4;
    } else if (diaHoy == 6) {
      diaHoy = 5;
    }

    // console.log(
    //   "diaHoy",
    //   diaHoy,
    //   "horaHoy",
    //   horaHoy,
    //   "minutosHoy",
    //   minutosHoy
    // );

    const scheduleToday = schedule[diaHoy];

    // console.log("SCHEDULE TODAY", scheduleToday);

    let horario0Hoy = {
      hor0open: 0,
      hor0close: 0,
      min0open: 0,
      min0close: 0,
    };

    let horario1Hoy = {
      hor1open: 0,
      hor1close: 0,
      min1open: 0,
      min1close: 0,
    };

    if (scheduleToday[0].open != "") {
      // console.log("scheduleToday[0]", scheduleToday[0]);

      horario0Hoy = {
        hor0open: parseInt(scheduleToday[0].open.split(":")[0]),
        hor0close: parseInt(scheduleToday[0].close.split(":")[0]),
        min0open: parseInt(scheduleToday[0].open.split(":")[1]),
        min0close: parseInt(scheduleToday[0].close.split(":")[1]),
      };
    }

    if (scheduleToday[1] != "") {
      // console.log("scheduleToday[1]", scheduleToday[1]);

      horario1Hoy = {
        hor1open: parseInt(scheduleToday[1].open.split(":")[0]),
        hor1close: parseInt(scheduleToday[1].close.split(":")[0]),
        min1open: parseInt(scheduleToday[1].open.split(":")[1]),
        min1close: parseInt(scheduleToday[1].close.split(":")[1]),
      };
    }

    // console.log("SCHEDULE TODAY", horario0Hoy, horario1Hoy);
    if (
      (horario0Hoy.hor0open <= horaHoy ||
        horario1Hoy.hor1open <= horaHoy) &&
      (horario0Hoy.hor0close >= horaHoy ||
        horario1Hoy.hor1close >= horaHoy)
    ) {
      if (
        (horario0Hoy.hor0open == horaHoy ||
          horario1Hoy.hor1open == horaHoy) &&
        (horario0Hoy.min0open <= minutosHoy ||
          horario1Hoy.min1open <= minutosHoy)
      ) {
        // console.log(
          // "!!!!!!!!!!!!!!!!!!!!ESTA ABIERTO!!!!!!!!!!!!!!!!!!!!!!"
        // );
        return true;
      } else if (
        (horario0Hoy.hor0close == horaHoy ||
          horario1Hoy.hor1close == horaHoy) &&
        (horario0Hoy.min0close >= minutosHoy ||
          horario1Hoy.min1close >= minutosHoy)
      ) {
        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!ESTA ABIERTO!!!!!!!!!!!!!!!!!!!!!!"
        // );
        return true;
      } else if (
        horario0Hoy.hor0open == horaHoy ||
        horario1Hoy.hor1open == horaHoy ||
        horario0Hoy.hor0close == horaHoy ||
        horario1Hoy.hor1close == horaHoy
      ) {
        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!ESTA CERRADO!!!!!!!!!!!!!!!!!!!!!!"
        // );
        return false;
      } else {
        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!ESTA ABIERTO!!!!!!!!!!!!!!!!!!!!!!"
        // );
        return true;
      }
    } else {
      // console.log(
      //   "!!!!!!!!!!!!!!!!!!!!ESTA CERRADO!!!!!!!!!!!!!!!!!!!!!!"
      // );
      return false;
    }
  }


  async hide() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }

  /*
    Show Warning Alert Message
    param : msg = message to display
    Call this method to show Warning Alert,
    */
  async showWarningAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Warning',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }
  async showWarningAlertNotification(title, body) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: body,
      buttons: ['OK']
    });

    await alert.present();
  }

  getCurrencyCode() {
    return environment.general.code;
  }

  getCurrecySymbol() {
    return environment.general.symbol;
  }


  getObservable(): Subject<any> {
    return this.events;
  }

  publishNewAddress(data: any) {
    this.events.next(data);
  }

  publishProfile(data: any) {
    this.profile.next(data);
  }

  obserProfile(): Subject<any> {
    return this.profile;
  }

  async showSimpleAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: '',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  translate(str) {
    const currentLang = this.translateService.currentLang;
    const returnValue = this.translateService.translations[currentLang][str];
    if (returnValue === undefined) {
      return this.translateService.translations.en_merch[str];
    } else {
      return returnValue;
    }
  }
  translateInEN(str) {
    const currentLang = 'en';
    const returnValue = this.translateService.translations[currentLang][str];
    if (returnValue === undefined) {
      return this.translateService.translations.en_merch[str];
    } else {
      return returnValue;
    }
  }

  /*
   Show Error Alert Message
   param : msg = message to display
   Call this method to show Error Alert,
   */
  async showErrorAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  /*
     param : email = email to verify
     Call this method to get verify email
     */
  async getEmailFilter(email) {
    const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    if (!(emailfilter.test(email))) {
      const alert = await this.alertCtrl.create({
        header: 'Warning',
        message: 'Please enter valid email',
        buttons: ['OK']
      });
      await alert.present();
      return false;
    } else {
      return true;
    }
  }


  /*
    Show Toast Message on Screen
     param : msg = message to display, color = background color
      of toast example dark,danger,light. position  = position of message example top,bottom
     Call this method to show toast message
     */

  async showToast(msg, colors, positon) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      color: colors,
      position: positon
    });
    toast.present();
  }
  async shoNotification(msg, colors, positon) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 4000,
      color: colors,
      position: positon,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            // console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

  async errorToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      color: 'danger'
    });
    toast.present();
  }

  apiErrorHandler(err) {
    // console.log('Error got in service =>', err)
    if (err.status === -1) {
      this.showErrorAlert('Failed To Connect With Server');
    } else if (err.status === 401) {
      this.showErrorAlert('Unauthorized Request!');
      this.navCtrl.navigateRoot('/login');
    } else if (err.status === 500) {
      this.showErrorAlert('Somethimg Went Wrong..');
    }
  }

  setDetails(data) {
    this.details = null;
    this.details = data;
  }
  getDetails() {
    return this.details;
  }
  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  

}

